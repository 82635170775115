export class TypedBroadcastChannel<T> {
  private channel: BroadcastChannel;

  constructor(channelName: string) {
    this.channel = new BroadcastChannel(channelName);
  }

  postMessage(message: T) {
    this.channel.postMessage(message);
  }

  addEventListener(
    type: 'message' | 'messageerror',
    listener: (this: BroadcastChannel, ev: MessageEvent<T>) => any,
    options?: boolean | AddEventListenerOptions
  ) {
    this.channel.addEventListener(type, listener as EventListener, options);
  }

  removeEventListener(
    type: 'message' | 'messageerror',
    listener: (this: BroadcastChannel, ev: MessageEvent<T>) => any,
    options?: boolean | EventListenerOptions
  ) {
    this.channel.removeEventListener(type, listener as EventListener, options);
  }

  get onmessage() {
    return this.channel.onmessage!;
  }

  set onmessage(listener: (this: BroadcastChannel, ev: MessageEvent<T>) => any) {
    this.channel.onmessage = listener as (this: BroadcastChannel, ev: MessageEvent) => any;
  }

  get onmessageerror() {
    return this.channel.onmessageerror!;
  }

  set onmessageerror(listener: (this: BroadcastChannel, ev: MessageEvent) => any) {
    this.channel.onmessageerror = listener;
  }

  close() {
    this.channel.close();
  }
}

export default TypedBroadcastChannel;
